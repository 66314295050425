import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Gallery from 'components/gallery';
import InputUser from 'components/forms/input-user';
import Chart from 'components/chart/chart';
import SelectYear from 'components/chart/select-years';
import Cart from 'components/checkout/cart';
import Warning from 'components/checkout/warning';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import loader from '../../content/images/loader.gif';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.fetchDataContributions = this.fetchDataContributions.bind(this);
    this.fetchShippingRate = this.fetchShippingRate.bind(this);
    this.changeYear = this.changeYear.bind(this);
    this.toggleSelectedProduct = this.toggleSelectedProduct.bind(this);
    this.setSKU = this.setSKU.bind(this);
    this.isFilledCart = this.isFilledCart.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.formValidatorSKU = this.formValidatorSKU.bind(this);
    this.formValidatorUsername = this.formValidatorUsername.bind(this);
    this.displayWarning = this.displayWarning.bind(this);
    this.invalidateWarning = this.invalidateWarning.bind(this);

    var initShoppingCart = [];
    this.props.data.homeJson.gallery.forEach(function(item) {
      var skus = [];
      item.stripe_sku.forEach(function(sku_obj) {
        skus.push({
          ...sku_obj,
          quantity: 0,
          sku: sku_obj['sku_' + process.env.NODE_ENV],
        });
      });
      initShoppingCart.push({
        id: item.slug,
        selected: false,
        price: item.price,
        stripe_sku: skus,
      });
    });

    this.state = {
      loader: false,
      datacontribution: {},
      year: new Date().getFullYear(),
      username: '',
      shoppingCart: initShoppingCart,
      shippingRate: {
        loader: true,
        sku: '',
        rate: 0,
      },
      warning: [
        {
          id: 'username',
          display: false,
          message: 'Please insert your username.',
        },
        {
          id: 'size',
          display: false,
          message: 'Please choose a size for your T-shirt.',
        },
      ],
    };
  }

  fetchDataContributions(data) {
    this.invalidateWarning();
    this.setState({
      loader: true,
      datacontribution: {},
      username: data.username,
    });
    fetch(`${process.env.GATSBY_API_URL}` + data.username, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        this.setState({
          loader: false,
          datacontribution: data,
          year: new Date().getFullYear(),
        });
      })
      .catch();
  }

  fetchShippingRate(data) {
    this.setState(prevState => ({
      shippingRate: {
        ...prevState.shippingRate,
        loader: true,
      },
    }));
    fetch(`${process.env.GATSBY_API_URL}` + 'shipping/rates', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        data.received.loader = false;
        this.setState({
          shippingRate: data.received,
        });
      })
      .catch();
  }

  changeYear(newYear) {
    this.setState({ year: newYear });
  }

  toggleSelectedProduct(product_id) {
    this.invalidateWarning();
    this.setState(prevState => ({
      shoppingCart: prevState.shoppingCart.map(el =>
        el.id === product_id ? { ...el, selected: !el.selected } : el
      ),
    }));
  }

  setSKU(product_id, sku_id, quantity) {
    this.invalidateWarning();
    this.setState(prevState => ({
      shoppingCart: prevState.shoppingCart.map(el => {
        var skuUpdated = el.stripe_sku.map(sku_obj =>
          sku_obj.sku === sku_id
            ? { ...sku_obj, quantity: quantity }
            : { ...sku_obj, quantity: 0 }
        );

        return el.id === product_id ? { ...el, stripe_sku: skuUpdated } : el;
      }),
    }));
  }

  isFilledCart() {
    var result = false;
    this.state.shoppingCart.map(el => {
      if (el.selected) result = true;
    });
    return result;
  }

  formValidatorSKU() {
    var result = true;
    this.state.shoppingCart.map(elt => {
      var quantity = 0;
      if (elt.selected)
        elt.stripe_sku.map(sku_obj => {
          quantity += sku_obj.quantity;
        });
      if (elt.selected && quantity === 0) result = false;
    });
    if (!result) this.displayWarning('size');
    return result;
  }

  formValidatorUsername() {
    var result = this.state.username !== '' && this.state.username !== null;
    if (!result) this.displayWarning('username');
    return result;
  }

  displayWarning(warning_id) {
    this.setState(prevState => ({
      warning: prevState.warning.map(el =>
        el.id === warning_id ? { ...el, display: true } : el
      ),
    }));
  }

  invalidateWarning() {
    this.setState(prevState => ({
      warning: prevState.warning.map(el =>
        el.display ? { ...el, display: false } : el
      ),
    }));
  }

  validateForm() {
    this.invalidateWarning();
    return this.formValidatorSKU() && this.formValidatorUsername();
  }

  render() {
    const { data } = this.props;
    var refId = this.state.year + ' ';
    return (
      <Layout>
        <Helmet>
          <meta
            content="GitBrick : Show off your Github contribution"
            name="apple-mobile-web-app-title"
          />
          <title>GitBrick : Show off your Github contribution</title>
        </Helmet>
        <Box>
          <Img
            fixed={
              data.homeJson.image
                ? data.homeJson.image.childImageSharp.fixed
                : {}
            }
            alt="Git developer"
          />
          <div
            dangerouslySetInnerHTML={{
              __html: data.homeJson.header.childMarkdownRemark.html,
            }}
          />
          <InputUser handleClick={this.fetchDataContributions} />
          {this.state.loader ? (
            <img src={loader} alt="Loading..." />
          ) : (
            <span></span>
          )}
        </Box>
        <Box>
          {this.state.datacontribution.years ? (
            [
              this.state.datacontribution.years.length > 0 ? (
                <div key={this.state.year}>
                  <h3>
                    <span className="dot">1</span>
                    Select your period :
                  </h3>
                  <SelectYear
                    data={this.state.datacontribution.years}
                    year={this.state.year}
                    handleChangeYear={this.changeYear}
                  />
                  <Chart
                    data={this.state.datacontribution}
                    year={this.state.year}
                    username={this.state.username}
                  />
                  <h3>
                    <span className="dot">2</span>
                    Select your product :
                  </h3>
                </div>
              ) : (
                <div key="errorNoAccount">
                  Sorry, this account doesn&apos;t exist or your contribution
                  information aren&apos;t public.
                  <a
                    href="https://help.github.com/en/articles/publicizing-or-hiding-your-private-contributions-on-your-profile"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    More information to changez your configuration.
                  </a>
                </div>
              ),
            ]
          ) : (
            <div></div>
          )}
        </Box>

        <Gallery
          items={data.homeJson.gallery}
          cart={this.state.shoppingCart}
          handleToggleSelected={this.toggleSelectedProduct}
          handleSKUChoice={this.setSKU}
        />
        {this.isFilledCart() ? (
          <Warning content={this.state.warning} />
        ) : (
          <div></div>
        )}
        {this.isFilledCart() ? (
          <Cart
            dataCart={this.state.shoppingCart}
            shippingRate={this.state.shippingRate}
            username={this.state.username}
            stripeParam={refId}
            handleValidateForm={this.validateForm}
            handleShippingRate={this.fetchShippingRate}
          />
        ) : (
          <div></div>
        )}
        <div style={{ height: '10vh' }} />
        <Box>
          <div
            style={{
              maxWidth: '800px',
              margin: '0 auto',
              textAlign: 'left',
              lineHeight: '1.5',
            }}
            dangerouslySetInnerHTML={{
              __html: data.homeJson.intro.childMarkdownRemark.html,
            }}
          />
        </Box>
      </Layout>
    );
  }
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      header {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      intro {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      image {
        childImageSharp {
          fixed(width: 320) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      gallery {
        title
        slug
        copy
        price
        stripe_sku {
          sku_development
          sku_production
          label
          order
        }
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
