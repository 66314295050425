import React from 'react';
import PropTypes from 'prop-types';

const Checkout = class extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.stripe = window.Stripe(process.env.GATSBY_STRIPE_PL);
  }

  async redirectToCheckout(event) {
    event.preventDefault();
    if (this.props.handleValidateForm()) {
      var items = [];

      for (var i = 0; i < this.props.dataCart.length; i++) {
        var product = this.props.dataCart[i];

        if (product.selected) {
          product.stripe_sku.forEach(function(sku_obj) {
            if (sku_obj.quantity > 0)
              items.push({ sku: sku_obj.sku, quantity: sku_obj.quantity });
          });
        }
      }
      items.push({ sku: this.props.shippingSku, quantity: 1 });
      const { error } = await this.stripe.redirectToCheckout({
        items: items,
        successUrl: `${process.env.GATSBY_URL}` + 'checkout/confirmed/',
        cancelUrl: `${process.env.GATSBY_URL}` + 'checkout/payment-failed/',
        clientReferenceId: this.props.username + ';' + this.props.stripeParam,
        billingAddressCollection: 'required',
      });

      if (error) {
        // eslint-disable-next-line
        console.warn('Error:', error);
      }
    }
  }

  render() {
    return (
      <button
        onClick={event => this.redirectToCheckout(event)}
        disabled={this.props.disable}
      >
        Buy it
      </button>
    );
  }
};
Checkout.displayName = 'Checkout';

Checkout.propTypes = {
  stripeParam: PropTypes.string.isRequired,
  shippingSku: PropTypes.string.isRequired,
  disable: PropTypes.bool.isRequired,
  dataCart: PropTypes.arrayOf(PropTypes.object).isRequired,
  username: PropTypes.string.isRequired,
  handleValidateForm: PropTypes.func.isRequired,
};
export default Checkout;
