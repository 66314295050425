import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { ItemBox, Title, Copy, Price } from './item.css';
import Global from '../../../global.js';

class SelectSku extends React.Component {
  constructor(props) {
    super(props);
    this.props.sku.sort((a, b) =>
      a.order > b.order ? 1 : b.order > a.order ? -1 : 0
    );
  }

  render() {
    var defaultValue = '';
    this.props.sku.map(elt => {
      if (elt.quantity > 0) defaultValue = elt.sku;
    });
    return (
      <select onBlur={this.props.handleSKUChoice} defaultValue={defaultValue}>
        {this.props.sku.map(elt => (
          <option value={elt.sku} key={elt.sku}>
            {elt.label}
          </option>
        ))}
      </select>
    );
  }
}

SelectSku.propTypes = {
  product: PropTypes.object.isRequired,
  sku: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSKUChoice: PropTypes.func.isRequired,
};

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.addProduct = this.addProduct.bind(this);
    this.changeSKU = this.changeSKU.bind(this);
  }

  addProduct(event) {
    event.preventDefault();
    this.props.handleToggleSelected(this.props.slug);
    if (this.props.stripe_sku.length === 1) {
      var firstSku = this.props.cart.stripe_sku[0];
      this.props.handleSKUChoice(this.props.slug, firstSku.sku, 1);
    }
  }

  changeSKU(event) {
    this.props.handleSKUChoice(this.props.slug, event.currentTarget.value, 1);
  }

  render() {
    const { image, title, copy, price, cart } = this.props;
    return (
      <ItemBox className={cart.selected ? 'itemBoxSelected' : ''}>
        <figure>
          <Img fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
          <figcaption>
            <Title>{title}</Title>
            <Copy>{copy}</Copy>
            <Price>
              {Global.currency}
              {price / 100}
            </Price>
            {cart.stripe_sku.length > 1 && cart.selected ? (
              <div>
                <span>Select your size : </span>
                <SelectSku
                  product={cart}
                  sku={cart.stripe_sku}
                  handleSKUChoice={this.changeSKU}
                />
              </div>
            ) : (
              ''
            )}
          </figcaption>
        </figure>
        {!cart.selected ? (
          <button onClick={this.addProduct}>Add it</button>
        ) : (
          <button onClick={this.addProduct} className="oppositeButton">
            Remove it
          </button>
        )}
      </ItemBox>
    );
  }
}

Item.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string,
  copy: PropTypes.string,
  slug: PropTypes.string,
  price: PropTypes.number,
  stripe_sku: PropTypes.arrayOf(PropTypes.object).isRequired,
  cart: PropTypes.object.isRequired,
  handleToggleSelected: PropTypes.func.isRequired,
  handleSKUChoice: PropTypes.func.isRequired,
};

export default Item;
