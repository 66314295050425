import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import selectYearsStyles from './select-years.css';

class SelectYear extends React.Component {
  constructor(props) {
    super(props);
    this.changeYear = this.changeYear.bind(this);
  }

  changeYear(e) {
    this.props.handleChangeYear(parseInt(e.target.value));
  }

  render() {
    // eslint-disable-next-line
    return (
      <div>
        {this.props.data ? (
          this.props.data.map((elt, i) => (
            <input
              type="button"
              key={i}
              value={elt.year}
              className={
                this.props.year == elt.year
                  ? 'year-button active'
                  : 'year-button'
              }
              onClick={this.changeYear}
            />
          ))
        ) : (
          <span></span>
        )}
      </div>
    );
  }
}

SelectYear.propTypes = {
  data: PropTypes.array.isRequired,
  year: PropTypes.number.isRequired,
  handleChangeYear: PropTypes.func.isRequired,
};

export default SelectYear;
