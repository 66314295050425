import React from 'react';
import PropTypes from 'prop-types';
import Checkout from './checkout';
// eslint-disable-next-line
import cartStyles from './cart.css';
import Global from '../../global.js';
import loader from '../../../content/images/loader.gif';

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.getSubtotal = this.getSubtotal.bind(this);
    this.getShippingFees = this.getShippingFees.bind(this);
    this.getTotal = this.getTotal.bind(this);
    this.getSubtotal();
  }

  componentDidMount() {
    this.props.handleShippingRate(this.props.dataCart);
  }
  componentDidUpdate(prevProps) {
    if (this.props.dataCart !== prevProps.dataCart) {
      this.props.handleShippingRate(this.props.dataCart);
    }
  }

  getSubtotal() {
    var subTotal = 0;
    this.props.dataCart.forEach(function(value) {
      if (value.selected) subTotal += value.price;
    });
    return subTotal;
  }

  getShippingFees() {
    var fee = 500;
    if (this.props.shippingRate.rate) {
      fee = this.props.shippingRate.rate;
    }
    return fee;
  }

  getTotal() {
    return this.getSubtotal() + this.getShippingFees();
  }

  render() {
    return (
      <div className="cart">
        <div>
          <span>SubTotal :</span>
          <span className="price">
            <span>
              {Global.currency}
              {this.getSubtotal() / 100}
            </span>
          </span>
        </div>
        <div>
          <span>Shipping fees :</span>
          <span className="price">
            {this.props.shippingRate.loader ? (
              <span></span>
            ) : (
              <span>
                {Global.currency} {this.getShippingFees() / 100}
              </span>
            )}
          </span>
        </div>
        <div className="total">
          <span>Total :</span>
          <span className="price">
            {this.props.shippingRate.loader ? (
              <span></span>
            ) : (
              <span>
                {Global.currency} {this.getTotal() / 100}
              </span>
            )}
          </span>
        </div>

        {this.props.shippingRate.loader ? (
          <div className="cart-loarder">
            <img src={loader} alt="Loading..." />
          </div>
        ) : (
          <span></span>
        )}
        <div className="proceed">
          <Checkout
            dataCart={this.props.dataCart}
            shippingSku={
              this.props.shippingRate.sku ? this.props.shippingRate.sku : ''
            }
            username={this.props.username}
            stripeParam={this.props.stripeParam}
            handleValidateForm={this.props.handleValidateForm}
            disable={this.props.shippingRate.loader}
          />
        </div>
      </div>
    );
  }
}
Cart.propTypes = {
  dataCart: PropTypes.arrayOf(PropTypes.object).isRequired,
  shippingRate: PropTypes.object.isRequired,
  stripeParam: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  handleValidateForm: PropTypes.func.isRequired,
  handleShippingRate: PropTypes.func.isRequired,
};

export default Cart;
