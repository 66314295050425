import React from 'react';
import PropTypes from 'prop-types';
import Item from 'components/gallery/item';
import { Container } from './gallery.css';

const Gallery = ({ items, cart, handleSKUChoice, handleToggleSelected }) => (
  <Container>
    {items.map(item => (
      <Item
        {...item}
        key={item.slug}
        cart={cart.find(p => p.id === item.slug)}
        handleToggleSelected={handleToggleSelected}
        handleSKUChoice={handleSKUChoice}
      />
    ))}
  </Container>
);

Gallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  cart: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleToggleSelected: PropTypes.func.isRequired,
  handleSKUChoice: PropTypes.func.isRequired,
};

export default Gallery;
