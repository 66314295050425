import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import warningStyles from './warning.css';

class Warning extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="warning">
        {this.props.content.map(elt => {
          if (elt.display) {
            return <div key={elt.id}>{elt.message}</div>;
          }
        })}
      </div>
    );
  }
}

Warning.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Warning;
