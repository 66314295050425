import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px;
`;

export const InputUsername = styled.input`
  height: 35px;
  width: 200px;
  border: 1px solid #eee;
  border-radius: 6px;
  margin: 10px 10px;
  padding: 0px 10px;
  font-size: 1em;
`;
