import React from 'react';
import PropTypes from 'prop-types';
import { Container, InputUsername } from './input-user.css';

class InputUser extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    var username = this.input.value;
    this.props.handleClick({ username });
    e.preventDefault();
  }

  render() {
    return (
      <Container>
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="username">
            Your Github Username
            <InputUsername
              id="username"
              type="text"
              placeholder="Example : fabpot, andrew..."
              ref={input => (this.input = input)}
            />
          </label>
          <button type="submit" value="Create">
            Create
          </button>
        </form>
      </Container>
    );
  }
}

InputUser.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default InputUser;
